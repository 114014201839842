body {
  margin: 0;
  padding: 0;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
  background-image: none !important;
  height: 0px;
}

.pac-container {
  z-index: 99999999;
}
